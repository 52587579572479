.Footer {
  background-color: #f5f5f5; /* Light background color */
  padding: 20px 0;
  width: 100%;
  text-align: center;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  position: fixed; /* Fixed position to keep the footer at the bottom */
  bottom: 0; /* Position the footer at the bottom of the viewport */
  left: 0; /* Ensure the footer spans the entire width */
  right: 0; /* Ensure the footer spans the entire width */
}

.Footer-nav {
  display: flex;
  justify-content: center;
  align-items: center; /* Center items vertically */
  gap: 20px;
}

.Footer-link {
  color: #000000; /* Your main color */
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.Footer-link:hover {
  color: #000000; /* Lighter shade on hover */
}

/* Media query for when the content is less */
@media (max-height: 100vh) {
  .Footer {
      position: relative; /* Change position to relative */
  }
}
