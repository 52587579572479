 /* search.css */

.search-bar {
    margin-bottom: 20px; /* Add some space below the search bar */
  }
  
  .search-input {
    width: calc(100% - 100px); /* Adjust width to accommodate the button */
    border-radius: 5px; /* Match border radius with the button */
    border: 1px solid #ccc;
    padding: 10px;
    outline: none; /* Remove default focus outline */
  }
  
  .search-button {
    background-color: #ff6d75; /* Theme color for the search button */
    color: white;
    border: none;
    border-radius: 5px; /* Match border radius with the input */
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #e85a62; /* Darker theme color on hover */
  }
  