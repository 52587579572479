.activity-purpose {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
  }
  
  .activity-purpose h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .purpose-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .purpose-option {
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px; /* Add margin between options */
  }
  
  .purpose-option:hover {
    transform: translateY(-2px);
  }
  
  .selected {
    background-color: #4caf50;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .purpose-options {
      flex-direction: column; /* Change to column layout on smaller screens */
    }
  
    .purpose-option {
      margin-bottom: 15px; /* Increase margin between options on smaller screens */
    }
  }
  