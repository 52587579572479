:root {
  --primary-color: #007bff; /* Replace with your primary theme color */
  --secondary-color: #6c757d; /* Replace with your secondary theme color */
  --light-color: #f5f5f5;
  --text-color: #333;
  --text-light-color: #666;
  --box-shadow-color: rgba(0, 0, 0, 0.1);
}

.App-header {
  background-color: var(--light-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--box-shadow-color);
}

.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  color: var(--text-color);
  margin-bottom: 20px;
}

.login-form {
  width: 100%;
}

.signup-link {
  margin-top: 20px;
  text-align: center;
}

.signup-link p {
  color: var(--text-light-color);
}

.signup-link a {
  color: var(--primary-color);
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

.login-button {
  margin-top: 10px;
  background-color: var(--primary-color);
  color: white;
}

.login-button:hover {
  background-color: var(--secondary-color);
}

/* Ensure TextField styles align with the theme */
.MuiFormControl-root {
  margin-bottom: 16px;
}

.MuiInputBase-root {
  background-color: var(--light-color);
}
