/* Preferences.css */
.App {
    text-align: center;
  }
  
  .App-header {
    background-color: #ff8c93;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .content-wrapper {
    max-width: 800px;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .preferences-box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .preference-item {
    background-color: #f0f0f0;
    border: 2px solid #ddd;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    color: black;
  }
  
  .preference-item:hover, .preference-item.selected {
    background-color: #ff8c93;
    color: white;
  }
  
  .MuiButton-containedPrimary {
    margin-top: 20px;
    background-color: #ff8c93;
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    border-radius: 25px;
  }
  
  .MuiButton-containedPrimary:hover {
    background-color: #ff5e6c;
  }
  
  @media (max-width: 600px) {
    .content-wrapper {
      padding: 20px;
    }
    
    .heading {
      font-size: 2em;
    }
  }
  