.blog-list-container {
  padding: 20px;
  background-color: #f0f0f0;
}

.blog-list-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 2.5em;
  font-weight: bold;
}

.blog-list-description {
  font-size: 1.2em;
  color: #666;
  text-align: center;
  margin-bottom: 30px;
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.search-bar input {
  width: 80%;
  max-width: 600px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-bar input:focus {
  border-color: #ff6d75;
}

.blog-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center; /* Center the cards horizontally */
  align-items: center; /* Center the cards vertically */
}


.blog-card-wrapper {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
}

.blog-card-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(255, 109, 117, 0.6);
}

.blog-card-link {
  text-decoration: none;
  color: inherit; /* Inherit color from parent */
  display: block;
}

/* Responsive Styles */
@media screen and (min-width: 600px) {
  .blog-cards-container {
    grid-template-columns: repeat(auto-fill, minmax(375px, 1fr)); /* Increase card width on larger screens */
  }
}.category-buttons {
  overflow-x: auto;
  margin: 10px; /* Add margin around the category buttons */
  
}

.category-list {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px; /* Add padding inside the category list */
}

.category-buttons button {
  padding: 6px 12px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  margin-right: 5px;
  white-space: nowrap; /* Ensure button text doesn't wrap */
  
}

.category-buttons button.active {
  background-color: #ff6d75;
  color: #fff;
  border-color: #ff6d75; 
}

.category-buttons button:hover {
  background-color: #ddd;
 
}

@media (max-width: 600px) {
  .category-list {
    overflow-x: auto; /* Enable horizontal scroll */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
    padding-bottom: 10px; /* Add padding to bottom to prevent scrollbar from touching content */
  }
  
  .category-buttons button {
    margin-bottom: 5px;

  }
  
  .category-buttons {
    margin-bottom: 10px; /* Add margin to the container */
  }

  /* Remove scroll bar line on mobile */
  ::-webkit-scrollbar {
    display: none;
  }
}
