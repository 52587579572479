/* ScrollableChips.css */

.scrollable-chips-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent chips from wrapping to the next line */
  }
  
  .scrollable-chips-paper {
    padding: 5px; /* Add padding to the Paper component */
    display: inline-block; /* Display chips in a single line */
    margin-bottom: 10px; /* Add margin at the bottom */
  }
  
  .chip {
    margin-right: 5px; /* Add space between chips */
  }
  