/* Home.css */

/* Container Styles */
.home-container {
  margin-top: 20px;
  background-color: #f5f5f5; /* Soft background color */
  padding: 20px; /* Add padding for spacing */
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Search Bar Styles */
.search-bar {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  width: 100%;
  max-width: 400px; /* Limit search input width for better readability */
  padding: 10px; /* Add padding for input */
  border: 1px solid #ddd; /* Light border */
  border-radius: 5px; /* Rounded corners */
  outline: none; /* Remove default focus outline */
  transition: border-color 0.3s ease; /* Smooth border color transition */
}

.search-input:focus {
  border-color: #ff6d75; /* Highlight border on focus with theme color */
}

.search-button {
  background-color: #ff6d75; /* Theme color for the search button */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin-left: 10px; /* Add space between input and button */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.search-button:hover {
  background-color: #e85a62; /* Darker theme color on hover */
}

/* Listings Container Styles */
.listings-container {
  margin-top: 20px;
}

/* Listing Card Styles (you can add more creative styles here if needed) */
.listing-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px; /* Add padding for spacing */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.listing-card:hover {
  transform: translateY(-5px); /* Lift card slightly on hover */
}
