/* Home.css */
.App {
  text-align: center;
}

.App-header {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  max-width: 800px;
  padding: 20px;
}

.intro-text {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333; /* Dark text color */
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.chip {
  font-size: 14px;
  background-color: #e1ddc3; /* Theme color */
  color: #000; /* White text color */
  padding: 8px 12px;
  border-radius: 20px;
}

.card-container {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  width: calc(33.333% - 20px); /* Adjust the width as needed */
  padding: 20px;
  background-color: #fff; /* White background */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.card-content {
  text-align: left; /* Align content to the left */
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  color: #333; /* Dark text color */
}

.card-text {
  color: #666; /* Light text color */
}

/* Additional Styling */
.image-container {
  margin-bottom: 20px;
}

.image-container img {
  max-width: 100%;
  border-radius: 10px;
}
.App-link:hover {
  color: #ff8c93;
}

.Blog-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #747371;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid transparent;
  cursor: pointer;
  margin-top: 10px; /* Adjust the value as needed */
}

.Blog-link:hover,
.Blog-link:focus {
  background-color: #ff6d75;
  color: #fff;
  border-color: #ff6d75;
}

.Blog-link:focus {
  outline: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(0deg);
  }
}
