/* BlogPost.css */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set container height to viewport height */
  }
.blog-post-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .blog-post-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog-post-header img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 10px;
  }
  
  .blog-post-header h2 {
    margin-top: 20px;
    font-size: 24px;
    color: #ff6d75;
  }
  
  .blog-post-content p {
    line-height: 1.6;
  }
  
  .blog-post-meta {
    margin-top: 20px;
    text-align: center;
  }
  
  .blog-post-meta p {
    margin-bottom: 5px;
  }
  
  .blog-post-meta strong {
    font-weight: bold;
  }
  
  /* Special Effects */
  .blog-post-container:hover {
    transform: translateY(-3px);
    transition: transform 0.3s ease;
  }
  .github-link {
    margin-top: 20px;
    text-align: center;
  }
  
  .github-link a {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #2e3440; /* Dark background color */
    color: #ffffff; /* White text color */
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .github-link a:hover {
    background-color: #4c566a; /* Darker background color on hover */
  }
  
  .github-link img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 10px;
  }
  
  .github-link span {
    vertical-align: middle;
  }
  
  .section-heading {
    color: #ff6d75; /* Red color */
    margin-bottom: 10px; /* Add some space below the heading */
  }
  
  .edge-cases-heading {
    color: #4caf50; /* Green color */
    margin-top: 20px; /* Add some space above the heading */
  }
  
  