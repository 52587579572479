.summary {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
  }
  
  .summary-title {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .summary-details {
    font-size: 16px;
  }
  
  .summary-details p {
    margin-bottom: 10px;
    color: #555;
  }
  
  .summary-details strong {
    color: #333;
  }
  