/* Basic styling for the header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 20px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo {
    max-width: 100px;
    max-height: 100%;
  }
  
  .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-link {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s;
  }
  
  .nav-link:hover {
    color: #000;
  }
  
  /* Hamburger menu */
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .nav-links {
      display: none;
      flex-direction: column;
      position: fixed;
      top: 70px;
      left: 0;
      width: 100%;
      background-color: #f5f5f5;
      padding: 20px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  
    .nav-links.open {
      display: flex;
    }
  
    .hamburger {
      display: flex;
    }
  }
  