.intro-container {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
    text-align: center;
  }
  
  .intro-container h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .intro-label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    color: #555;
  }
  
  .intro-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .intro-textarea:focus {
    border-color: #4caf50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.2);
    outline: none;
  }
  
  .intro-textarea.valid {
    border-color: #4caf50;
  }
  
  .intro-textarea.invalid {
    border-color: #f44336;
  }
  
  @media (max-width: 768px) {
    .intro-container {
      padding: 15px;
    }
  
    .intro-container h3 {
      font-size: 20px;
    }
  
    .intro-textarea {
      padding: 8px;
      font-size: 14px;
    }
  }
  