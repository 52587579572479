.activity-place {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
  }
  
  .activity-place h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .place-options {
    display: flex;
    justify-content: space-between;
  }
  
  .place-option {
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .place-option:hover {
    transform: translateY(-2px);
  }
  
  .selected {
    background-color: #4caf50;
    color: #fff;
  }
  