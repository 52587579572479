/* Signup.css */
:root {
  --primary-color: #ff8c93; /* Replace with your primary theme color */
  --secondary-color: #ff5e6c; /* Replace with your secondary theme color */
  --light-color: #f5f5f5;
  --text-color: #333;
  --text-light-color: #666;
  --box-shadow-color: rgba(0, 0, 0, 0.2);
  --chip-selected-bg: var(--primary-color);
  --chip-selected-color: white;
  --chip-default-bg: white;
  --chip-default-color: var(--text-color);
  --chip-border-radius: 16px;
  --chip-padding: 8px 16px;
}

.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.content-wrapper {
  max-width: 500px; /* Adjusted to make it less wide on desktop */
  width: 100%;
  padding: 30px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  box-shadow: 0 8px 16px var(--box-shadow-color);
  animation: fadeIn 1s ease-in-out;
  box-sizing: border-box;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.signup-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.heading {
  color: var(--text-color);
  margin-bottom: 30px;
  font-size: 2.5em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Ensure center alignment */
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.chip-container p {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.1em;
  color: #555;
  text-align: center;
}

.MuiChip-root {
  margin: 5px;
  border-radius: var(--chip-border-radius);
  padding: var(--chip-padding);
  font-size: 1em;
  background-color: var(--chip-default-bg);
  color: var(--chip-default-color);
  border: 1px solid var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.MuiChip-root:hover {
  background-color: var(--chip-selected-bg);
  color: var(--chip-selected-color);
}

.MuiChip-root.MuiChip-clickablePrimary {
  background-color: var(--chip-selected-bg);
  color: var(--chip-selected-color);
}

.MuiButton-containedPrimary {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.MuiButton-containedPrimary:hover {
  background-color: var(--secondary-color);
}

.MuiTextField-root {
  margin: 10px;
  width: calc(100% - 20px); /* Adjust width to be responsive */
}

@media (max-width: 600px) {
  .content-wrapper {
    width: 100%;
    padding: 20px;
  }

  .heading {
    font-size: 2em;
  }

  .MuiTextField-root {
    width: 100%;
  }
}

.textfield-title {
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
  font-size: 1.1em;
  color: var(--text-light-color); /* Uses a CSS variable for consistency */
}
