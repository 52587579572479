/* BlogPostGeneral.css */

/* Loader */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set container height to viewport height */
}

/* Blog Post Container */
.blog-post-container {
  margin: 20px auto; /* Added margin for breathing space */
  padding: 20px;
  border-radius: 10px;
  background-color: #f4f4f4; /* Light gray background color */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  color: #333; /* Dark gray text color */
}

/* Header Image */
.header-image {
  text-align: center;
  margin-bottom: 20px;
}

.header-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  display: block; /* Ensure the image behaves as a block element */
  margin: 0 auto; /* Center the image horizontally */
}

/* Section Title */
.section-title {
  color: #ff6d75; /* Red color */
  margin-bottom: 10px; /* Add some space below the heading */
  text-align: center; /* Center align section titles */
}

/* Blog Post Content */
.section-content {
  line-height: 1.6;
  margin-bottom: 20px; /* Add space below each content section */
  text-align: justify; /* Justify text for better readability */
}

/* Responsive Styling */
@media screen and (max-width: 600px) {
  .header-image img {
    width: 100%; /* Ensure the image fills the container width */
    height: auto; /* Maintain aspect ratio */
  }
  .post-title {
    font-size: 20px;
  }
  .section-title {
    font-size: 20px;
  }
  .section-content {
    text-align: center; /* Center align text on smaller screens */
  } 
}

/* Typography */
.post-title {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  margin-bottom: 20px;
}

.section-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.section-content {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #555;
}

.meta-info {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #777;
  text-align: center;
}

/* Image Gallery */
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10px;
}

.section-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
}
