
.exp-details-container {
  margin-top: 40px;
}

.title {
  margin-bottom: 20px;
}

.location-host {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.price-attend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.divider {
  margin-bottom: 20px;
}

.details-grid {
  margin-top: 20px;
}

.details-grid .MuiTypography-root {
  margin-bottom: 10px;
}

.details-grid .MuiTypography-root:last-child {
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .details-grid .MuiGrid-item {
    margin-bottom: 20px;
  }
}